html {
  background: unset;
}
html,
body {
  display: block;
  margin: 0;
  padding: 0;
}

body {
  background: #006db7;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 22px;
}

body#rootBody {
  background-color: #3d7a1d !important;
  background-repeat: no-repeat;
  background-position-x: center !important;
}

img {
  vertical-align: middle;
  border: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.text-en,
.text-ru {
  cursor: default;
}

.hide {
  bottom: -460px !important;
  display: none;
}

a.home-link {
  display: block;
  color: #006dba;
  line-height: 35px;
  margin-bottom: 10px;
}

.about-link {
  position: absolute;
  top: 60%;
  font-size: 0.91em;
  left: 0;
  right: 0;
  color: #5f9849;
  font-weight: 500;
  line-height: 1.3rem;
}

.home-link.close {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 0px;
  top: 3px;
  width: 50px;
  height: 50px;
  text-decoration: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.close .landing-auth-close {
  right: auto;
}

a,
span,
div,
input,
button {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

button {
  cursor: default;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0em;
  border-width: 0;
  border-style: outset;
  border-color: transparent;
  -o-border-image: initial;
  border-image: initial;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

.container {
  max-width: 480px;
  margin: 0 auto;
  background: #003b00;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 9px 6px #fffdcc !important;
}

@media screen and (max-height: 600px) {
  .landing {
    min-height: 600px !important;
  }
  .landing-auth {
    top: auto !important;
    bottom: 0 !important;
  }
}

.landing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  height: 100%;
  width: 100%;
  z-index: 3;
  display: block;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  opacity: 0;
  visibility: hidden;
}

.notice-show .landing-overlay {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  opacity: 1;
  visibility: visible;
}

.auth-show .landing-overlay {
  opacity: 1;
  visibility: visible;
}

.auth-show .landing-auth {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.landing-auth-title {
  font-size: 25px;
  font-weight: bold;
  position: relative;
  text-align: center;
}

.landing-auth-title img {
  margin-top: -5px;
}

.landing-auth-description {
  font-size: 16px;
  padding: 0 5%;
  margin: 10px 0 12px 0;
  text-align: center;
}

.landing-auth form {
  position: relative;
}

.landing-auth input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin: auto;
  background-color: #1f550e;
  border-radius: 24px;
  font-size: 1em;
  line-height: 1.65rem;
  font-weight: bold;
  padding: 6px 14px;
  width: 95%;
  color: #ffffff;
  border: 0;
  border-top: 2px solid #194b05;
  border-bottom: 2px solid #246b08;
  -webkit-transition: background-color 5000s ease-in-out 5000s, border-color 0.3s ease;
  -o-transition: background-color 5000s ease-in-out 5000s, border-color 0.3s ease;
  transition: background-color 5000s ease-in-out 5000s, border-color 0.3s ease;
}

.landing-auth input:focus {
  outline: none;
  border: 2px solid #62a64c;
}

.landing-auth input:not(:first-child) {
  padding-right: 10%;
  margin-top: 12px;
}

.landing-auth input::-webkit-input-placeholder {
  color: #62a64c;
}

.landing-auth input::-moz-placeholder {
  color: #62a64c;
}

.landing-auth input:-ms-input-placeholder {
  color: #62a64c;
}

.landing-auth input::-ms-input-placeholder {
  color: #62a64c;
}

.landing-auth input::placeholder {
  color: #62a64c;
}

input:-webkit-autofill {
  background-color: #1f550e !important;
}

.landing-auth-close {
  position: absolute;
  cursor: pointer;
  width: 21px;
  height: 21px;
  background-position: 55% 95%;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#landing-auth-close.landing-auth-close {
  position: absolute;
  cursor: pointer;
  width: 21px !important;
  height: 21px !important;
  background-position: 55% 95%;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#password-view.landing-icons {
  background-position: 0 52%;
  top: 62px;
  right: 6%;
}

#password-view.landing-icons.show {
  background-position: 0 1%;
}

.landing-auth-forgot {
  color: #63ab4c;
  display: inline-block;
  margin: 5px 0 15px 6%;
}

.landing-auth-forgot span {
  text-decoration: underline;
}

.input.input-error,
.input.input-error:focus {
  border: 2px solid #ff9279;
}

.input-error-text {
  color: #ff9279;
  font-size: 0.86em;
  margin: 4px 0 0 6%;
}

.landing-auth-social {
  /*margin-top: 8%;*/
}

.landing-auth-social .landing-login-label {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.landing-auth-politics {
  font-size: 0.91em;
  margin: 5% 0 0 0;
  text-align: center;
  color: #63ab4c;
}

.landing-auth-politics a {
  color: #63ab4c;
}

.landing-auth-button:focus {
  border: 0;
  outline: none;
}

.landing-notice-description {
  position: relative;
  text-align: center;
  margin: auto;
  margin-top: 18%;
  width: 88%;
  margin-bottom: 2%;
}

.landing-notice-agreement,
.landing-notice-confidentiality {
  position: relative;
  margin-top: 15px;
  color: #fff200;
  text-align: center;
  display: block;
  font-weight: bold;
}

.landing-notice-button {
  text-align: center;
}

.notice-show .landing-notice {
  display: block;
}

.landing-hint {
  position: absolute;
  width: 100%;
  left: 0;
  /* top: calc(71% - 24px); */
  top: 63vh;
  font-size: 1em;
  text-align: center;
  font-weight: 700;
  color: #fdfcea;
}

.landing-lang {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 16px;
  z-index: 2;
}

#selector-closed {
  display: block;
  background: #0078c5;
  border-radius: 16px;
  padding: 6px 15px;
  font-weight: bold;
  box-shadow: inset 0px 0px 16px #00297d;
  padding-right: 25px;
  cursor: pointer;
}

.selector-opened {
  display: none;
  color: #094066;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  border-radius: 16px;
  padding: 6px 12px;
  box-shadow: 0px 0px 6px #006fbd;
  position: absolute;
  top: -2px;
  width: 130px;
  right: 0;
  transition: all 0.4s ease-out;
  opacity: 0;
}

.selector-opened.show {
  opacity: 1;
}

.selector-opened span {
  display: block;
  padding: 4px;
  cursor: pointer;
}

.lang-ru span.selector-opened-en:after {
  content: '';
  background: transparent;
}

.lang-en span.selector-opened-ru:after {
  content: '';
  background: transparent;
}

div.selector-closed-ru {
  display: block;
}

div.selector-closed-en {
  display: none;
}

.lang-en div.selector-closed-ru {
  display: none;
}

.lang-en div.selector-closed-en {
  display: block;
}

.landing-admin-link {
  display: block;
  text-align: center;
  color: #0078c5;
  font-weight: 700;
  position: absolute;
  top: 10px;
  left: 10px;
}

.landing-footer {
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  text-align: center;
  color: #5a9547;
}

.landing-footer .landing-login-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.landing-login {
  padding: 0% 2% 3% 2%;
  line-height: 14px;
}

.landing-login-label {
  color: #5f9849;
  font-weight: 500;
  font-size: 0.91em;
  line-height: 1.3rem;
}

.landing-login-label .text-en,
.landing-login-label .text-ru {
  margin-right: 1%;
  margin-top: 8px;
}

.landing-auth .landing-login-label {
  padding: 10px;
  display: block;
}

.landing-login-label img {
  position: relative;
  margin-top: -5px;
}

@media screen and (min-height: 1040px) {
  .landing {
    background-position: 50% 0% !important;
  }
}
@media screen and (max-width: 480px) {
  body {
    font-size: 18.5px !important;
  }

  .landing-login-label img {
    height: 16.75px;
  }

  .landing-auth {
    font-size: 18px;
  }

  .landing-notice-ok {
    width: 50%;
  }

  .landing-notice {
    font-size: 21px;
  }
}

@media screen and (max-width: 480px) {
  .landing-login-label {
    font-size: 1em;
  }
}
@media screen and (max-width: 440px) {
  .landing-notice {
    font-size: 19px;
  }
  .landing-login-label {
    font-size: 0.93em;
  }
}
@media screen and (max-width: 400px) {
  .landing-notice {
    font-size: 17px;
  }
  .landing-notice-button a {
    margin-top: 8%;
  }
  .landing-login-label {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 390px) {
  .landing-login-label {
    font-size: 1em;
  }
}
@media screen and (max-width: 370px) {
  .landing-notice {
    font-size: 16px;
  }
  .landing-notice-button a {
    margin-top: 6%;
  }
  .landing-login-label {
    font-size: 0.95em;
  }
}
@media screen and (max-width: 340px) {
  .landing-notice {
    font-size: 15px;
  }
  .landing-login-label img {
    height: 14px;
  }
  .landing-login-label {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 320px) {
  .landing-notice {
    font-size: 14px;
  }
  .landing-login-label {
    font-size: 0.87em;
  }
  .landing-login-label img {
    height: 13px;
  }
}
@media screen and (max-width: 300px) {
  .landing-notice {
    font-size: 13.5px;
  }
  .landing-login-label img {
    height: 12px;
  }
  .landing-login-label {
    font-size: 0.75em;
  }
}
@media screen and (max-width: 285px) {
  .landing-notice {
    font-size: 13px;
  }
  .landing-login-label img {
    height: 11px;
  }
}
@media screen and (max-width: 270px) {
  .landing-notice {
    font-size: 12px;
  }
  .landing-notice-button a {
    margin-top: 5%;
  }
  .landing-login-label {
    font-size: 0.75em;
  }
  .landing-login-label img {
    height: 11px;
  }
  .landing-login-button {
    min-width: 25%;
    width: 25%;
  }
}
@media screen and (max-width: 250px) {
  .landing-notice {
    font-size: 11.5px;
  }
  .landing-notice-button a {
    margin-top: 9%;
  }
  .landing-login-label {
    font-size: 0.7em;
  }
  .landing-notice-agreement,
  .landing-notice-confidentiality {
    margin-top: 10px;
  }
}
@media screen and (max-width: 240px) {
  .landing-notice-button a {
    margin-top: 4%;
  }
  .landing-login-label {
    font-size: 0.68em;
  }
  .landing-login-label img {
    height: 10px;
  }
  .landing-login-button {
    min-width: 24%;
    width: 24%;
  }
}
@media screen and (max-width: 225px) {
  .landing-notice {
    font-size: 11px;
  }
  .landing-notice-agreement,
  .landing-notice-confidentiality {
    margin-top: 8px;
  }
  .landing-login-label {
    font-size: 0.64em;
  }
  .landing-login-label img {
    height: 9.5px;
  }
}
@media screen and (max-width: 210px) {
  .landing-notice {
    font-size: 10.5px;
  }
  .landing-notice-button a {
    width: 45%;
  }
}
@media screen and (max-width: 200px) {
  .landing-notice {
    font-size: 10px;
  }
}

@media screen and (max-width: 390px) {
  body {
    font-size: 14.5px !important;
  }

  .landing-auth {
    font-size: 14.5px;
  }
}

@media screen and (max-height: 900px) {
  .landing-hint {
    top: calc(71% - 25px) !important;
  }

  .landing-btn-start {
    top: 72% !important;
  }
}

@media screen and (max-width: 340px) {
  .landing-auth-title {
    font-size: 18px;
  }

  .landing-auth-description {
    font-size: 14px;
  }
}

.social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px;
  justify-content: center;
}

.social-list-item {
  padding: 5px;
}

.btn-soc {
  display: inline-block;
  overflow: hidden;
  border-radius: 10%;
  font-size: 0;
  text-decoration: none;
}

.soc-img {
  width: 52px;
  height: 52px;
  position: relative;
  display: inline-block;
  background-size: cover;
}

.sber-links {
  width: 80%;
  margin: 0 auto;
  color: #fff200;
  font-weight: 700;
  font-size: 22px;
  line-height: 121.4%;
  text-shadow: 0px 4px 30px rgba(41, 91, 1, 0.82);
}

.sber-notice .landing-notice-description {
  font-weight: 700;
  font-size: 22px;
  line-height: 121.4%;
  width: 85%;
}

.sber-text-license {
  padding: 0 20px;
  height: 35px;
  position: absolute;
  bottom: -30px;
  overflow: hidden;
  font-size: 10px;
  cursor: pointer;
}

.sb-hide .landing-notice-description {
  display: none;
}

.sb-hide .sber-links {
  display: none;
}

.sb-hide .landing-notice-button {
  display: none;
}

.sb-hide .sber-text-license {
  position: relative;
  bottom: auto;
  top: 70px;
  overflow: visible;
  font-size: 16px;
  line-height: 20px;
  padding: 0 30px;
  height: auto;
}

.sb-hide .sber-text-license .content {
  height: 100%;
  overflow: visible;
}

.sb-hide .more {
  margin-top: 15px;
  font-size: 30px;
}

.sber-text-license .content {
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}

.sber-text-license .content a {
  color: #fff;
}

.sber-text-license .more {
  cursor: pointer;
}

.sber-notice .landing-notice-button a {
  background-size: auto 100%;
  background-position: center;
}

.sber-check-grup a {
  display: inline;
}

.sber-check-grup {
  display: block;
  position: relative;
  margin-top: 15px;
}

.sber-links .sber-check-grup:last-child .sber-check {
  left: 30px;
}

a.disabled {
  pointer-events: none;
  opacity: 55%;
}

@media screen and (max-width: 480px) {
  .sb-hide .sber-text-license {
    top: calc(100vw / (480 / 70));
    font-size: calc(100vw / (480 / 16));
    line-height: calc(100vw / (480 / 20));
    padding: 0 calc(100vw / (480 / 30));
  }

  .sb-hide .more {
    margin-top: calc(100vw / (480 / 15));
    font-size: calc(100vw / (480 / 30));
  }

  .sber-notice .landing-notice-description {
    font-size: calc(100vw / (480 / 22));
  }
  .landing-notice.sber-notice {
    height: calc(100vw / (480 / 650));
  }
  .sber-links {
    font-size: calc(100vw / (480 / 22));
    margin-top: calc(100vw / (480 / 15));
    line-height: 121.4%;
  }
  .sber-links a {
    margin-top: calc(100vw / (480 / 15));
  }
  .sber-notice .landing-notice-button a {
    height: calc(100vw / (480 / 90));
  }
  .sber-check {
    width: calc(100vw / (480 / 26));
    height: calc(100vw / (480 / 26));
    left: calc(100vw / (480 / 74));
  }

  .sber-check-grup {
    margin-top: calc(100vw / (480 / 15));
  }

  .sber-links .sber-check-grup:last-child .sber-check {
    left: calc(100vw / (480 / 30));
  }
}

.about {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  max-width: 480px;
  height: 400px;
  z-index: 3;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  -webkit-transition-property: bottom;
  -o-transition-property: bottom;
  transition-property: bottom;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.landing {
  width: 100%;
  height: 100vh !important;
  min-height: 600px !important;
  max-height: 1080px;
}

.landing-auth {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 21px;
  text-align: left;
  padding: 4%;
  background-color: #206306;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 24px 24px 0 0;
  z-index: 4;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  overflow: hidden;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.landing-icons {
  width: 35px;
  height: 27px;
  position: absolute;
  cursor: pointer;
}

.landing-auth-button {
  background-color: transparent;
  width: 55%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: auto;
  display: block;
  padding-bottom: 27%;
}

.landing-notice {
  width: 90%;
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  -webkit-transform: translateY(-55%);
  left: 5%;
  z-index: 3;
  min-height: 418px;
  display: none;
  overflow: hidden;
}

.landing-notice-button a {
  width: 50%;
  height: 90px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  position: relative;
  margin: 10% auto;
}

.landing-btn-start {
  position: absolute;
  user-select: none;
  cursor: pointer;
  z-index: 2;
  font-size: 1.1em;
  font-weight: 900;
  width: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 68vh;
  text-align: center;
  padding: 11% 0;
  text-transform: uppercase;
}

.landing-logo {
  padding: 0 0 26% 0;
  position: absolute;
  top: 12%;
  width: 100%;
}

.lang-en .landing-logo {
  padding: 0 0 25% 0;
}

#selector-closed:after {
  content: '';
  position: absolute;
  top: 11px;
  right: 5px;
  width: 16px;
  height: 10px;
}

.lang-ru span.selector-opened-ru:after {
  content: '';
  padding-left: 5px;
  position: absolute;
  top: 11px;
  right: 5px;
  width: 12px;
  height: 12px;
}

.lang-en span.selector-opened-en:after {
  content: '';
  padding-left: 5px;
  position: absolute;
  top: 39px;
  right: 5px;
  width: 12px;
  height: 12px;
}

.landing-login-button {
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 27%;
  min-width: 27%;
  padding: 6.5% 0;
  cursor: pointer;
  display: inline-block;
}

.landing-notice.sber-notice {
  width: 100%;
  left: 0%;
  height: 650px;
  overflow: visible;
}

.sber-check {
  width: 26px;
  height: 26px;
  display: block;
  position: absolute;
  z-index: 1;
  left: 74px;
  cursor: pointer;
}

/* ================== Арт ================== */

.about {
  background: url('../images-480/logo-teaser.png') no-repeat 100% 100% #effad4;
  background-size: 100%;
}

.landing {
  background: url('../images-480/landing/landing.png') 50% 35%/100% auto no-repeat;
}

.landing-auth {
  background-image: url('../images-480/landing/bottom-art.png');
  background-repeat: no-repeat;
  background-position: 0 100%;
}

.landing-icons {
  background-image: url('../images-480/landing/ui-icon.png');
}

.landing-auth-button {
  background-image: url('../images-480/landing/enter.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.lang-en .landing-auth-button {
  background-image: url('../images-480/landing/enter-en.png');
}

.landing-notice {
  background-image: url('../images-480/landing/popup.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.lang-en .landing-notice {
  background-image: url('../images-480/landing/popup-en.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.landing-notice-button a {
  background-image: url('../images-480/landing/ok.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.landing-btn-start {
  background-image: url('../images-480/landing/start-game.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.lang-en .landing-btn-start {
  background-image: url('../images-480/landing/start-game-en.png');
}

.landing-logo {
  background-image: url('../images-480/landing/logo-my-farm.png');
  /*background-image: url('../images/logo_amazing_farm.png');*/
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50% 50%;
}

.lang-en .landing-logo {
  background-image: url('../images/logo_en_amazing_farm.png');
}

#selector-closed:after {
  background-image: url('../images-480/landing/point.png');
}

.lang-ru span.selector-opened-ru:after,
.lang-en span.selector-opened-en:after {
  background-image: url('../images-480/landing/selector.png');
}

.landing-login-button {
  background-image: url('../images-480/landing/sing-in.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.lang-en .landing-login-button {
  background-image: url('../images-480/landing/sing-in-en.png');
}

.soc-img-vk {
  background-image: url('../images-480/landing/vk.png');
}

.soc-img-ok {
  background-image: url('../images/social-odnoklassniki-64x64.png');
}

.soc-img-fb {
  background-image: url('../images-480/landing/fb.png');
}

.soc-img-tw {
  background-image: url('../images/social-twitter.svg');
}

.landing-notice.sber-notice {
  background-image: url('../images-480/landing/popup-long.png');
}

.sber-check {
  background: url('../images-480/landing/selector2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}

.sber-check._sb-check {
  background: url('../images-480/landing/selector3.png'), url('../images-480/landing/selector2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 120%, 80%;
}

/* ================== Арт ================== */

@media screen and (min-height: 1080px) {
  .landing-hint {
    top: 680px;
  }
  .landing-btn-start {
    top: 730px;
  }
  .main-frame-right,
  .main-frame-left {
    height: 1080px !important;
    max-height: 1080px !important;
    min-height: 1080px !important;
  }
}
